<template>
  <div>
    <v-row>
      <v-col cols="12">
        <f-card-title>{{ $t('pages.inventory.new.columnsRelation.definition') }}</f-card-title>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <table>
          <tbody>
            <tr>
              <td>{{ $t('pages.inventory.new.columnsRelation.inventoryDate') }}</td>
              <td>
                <v-select
                  v-model="date"
                  outlined
                  dense
                  hide-details="auto"
                  :items="headers.date"
                  :label="$t('labels.selectColumn')"
                />
              </td>
            </tr>
            <tr>
              <td>Fluke Part Number*</td>
              <td>
                <v-select
                  v-model="partNumber"
                  outlined
                  dense
                  hide-details="auto"
                  :items="headers.partNumber"
                  :label="$t('labels.selectColumn')"
                />
              </td>
            </tr>
            <tr>
              <td>{{ $t('labels.qtd') }} *</td>
              <td>
                <v-select
                  v-model="quantity"
                  outlined
                  dense
                  hide-details="auto"
                  :items="headers.quantity"
                  :label="$t('labels.selectColumn')"
                />
              </td>
            </tr>
            <tr>
              <td>{{ $t('labels.purchasePrice') }}</td>
              <td>
                <v-select
                  v-model="price"
                  outlined
                  dense
                  hide-details="auto"
                  :items="headers.price"
                  :label="$t('labels.selectColumn')"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    headers: {
      main: [],
      used: [],
      date: [],
      partNumber: [],
      quantity: [],
      price: [],
    },
  }),
  computed: {
    date: {
      get() {
        return this.$store.state.inventory.view.config.headers.date;
      },
      set(value) {
        this.$store.commit("inventory/updateDate", value);
      },
    },
    partNumber: {
      get() {
        return this.$store.state.inventory.view.config.headers.partNumber;
      },
      set(value) {
        this.$store.commit("inventory/updatePartNumber", value);
      },
    },
    quantity: {
      get() {
        return this.$store.state.inventory.view.config.headers.quantity;
      },
      set(value) {
        this.$store.commit("inventory/updateQuantity", value);
      },
    },
    price: {
      get() {
        return this.$store.state.inventory.view.config.headers.price;
      },
      set(value) {
        this.$store.commit("inventory/updatePrice", value);
      },
    },
    file() {
      return this.$store.state.inventory.view.file;
    },
  },
  watch: {
    date(value) {
      this.checkHeaderArr();
    },
    partNumber(value) {
      this.checkHeaderArr();
    },
    quantity(value) {
      this.checkHeaderArr();
    },
    price(value) {
      this.checkHeaderArr();
    },
  },
  created() {
    this.readFileHeaders();
  },
  methods: {
    async readFileHeaders() {
      const result = await this.$store.dispatch("inventory/readFileHeaders");
      if (result.length < 2) {
        this.$store.commit("snackbar/show", {
          content: this.$t('snackbar.malFormFile'),
          type: "error",
        });
        this.back();
        return;
      }

      this.headers.main = result.map((item) => {
        return {
          text: item,
          value: item,
          disabled: false,
        };
      });
      this.headers.date = JSON.parse(JSON.stringify(this.headers.main));
      this.headers.date.push({ text: "Não relacionado", value: "-" });
      this.headers.partNumber = JSON.parse(JSON.stringify(this.headers.main));
      this.headers.quantity = JSON.parse(JSON.stringify(this.headers.main));
      this.headers.price = JSON.parse(JSON.stringify(this.headers.main));
      this.headers.price.push({ text: "Não relacionado", value: "-" });
    },
    checkHeaderArr(header, value) {
      const localIndexes = ["date", "partNumber", "quantity", "price"];
      this.headers.used = [];
      var val;
      localIndexes.forEach((item) => {
        val = this.$store.state.inventory.view.config.headers[item];
        if (val && !this.headers.used.includes(val)) {
          this.headers.used.push(val);
        }
      });

      localIndexes.forEach((item) => {
        this.headers[item].map((it) => {
          if (this.headers.used.includes(it.value) && it.value !== "-") {
            it.disabled = true;
          } else {
            it.disabled = false;
          }
          return it;
        });
      });

      var itemVal;
      localIndexes.forEach((item) => {
        itemVal = this.$store.state.inventory.view.config.headers[item];
        try {
          this.headers[item].filter(
            (it) => it.value == itemVal
          )[0].disabled = false;
        } catch (e) {
          //
        }
      });
    },
    back() {
      this.$router.push({
        name: "inventory-upload",
      });
    },
  },
};
</script>
