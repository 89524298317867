<template>
	<div>
		<!-- {{$store.state.inventory.view}} -->
		<f-default-header
			:title="$t('pages.inventory.title')"
			:subtitle="$t('pages.inventory.setDefault.subtitle')" />

		<f-card>
			{{ $t("pages.inventory.setDefault.firstTime") }}
			<br />
			<br />
			<f-set-columns />
			<br />
			<f-set-currency-pattern />
		</f-card>

		<f-actions-btn-holder>
			<v-btn color="secondary" elevation="0" large @click="back">voltar</v-btn>

			<v-btn color="primary" elevation="0" large @click="setDefaults">
				continuar
			</v-btn>
		</f-actions-btn-holder>
	</div>
</template>

<script>
import FSetColumns from "../../components/inventory/ColumnsRelation.vue";
import FSetCurrencyPattern from "../../components/inventory/CurrencyPattern.vue";

export default {
	components: {
		FSetColumns,
		FSetCurrencyPattern,
	},
	data: () => ({
		// headers: null
	}),
	// computed: {
	//     file() {
	//         return this.$store.state.inventory.view.file
	//     }
	// },
	// created() {
	//     this.readFileHeaders()
	// },
	methods: {
		// async readFileHeaders(){
		//     const result = await this.$store.dispatch( 'inventory/readFileHeaders' )
		//     console.log(
		//         'bahHmpf',
		//         result
		//     )
		// },
		async setDefaults() {
			// console.log('bah',this.$store.state.inventory.view)

			// set headers
			await this.$store.dispatch("inventory/setHeadersConfig");

			this.$router.push({
				name: "inventory-upload-process",
				params: { date: this.$route.params.date },
			});

			// // proccess file
			// await this.$store.dispatch( 'inventory/dataProccess' )

			// var goToTotals = false
			// const totals = this.$store.state.inventory.view.totals
			// for( var prop in this.$store.state.inventory.view.totals ){
			//     if( !totals[prop] ){
			//         goToTotals = true
			//     }
			// }

			// if( goToTotals ){
			//     this.$router.push({
			//         name: 'inventory-missing-data'
			//     })
			// } else {
			//     this.$router.push({
			//         name: 'inventory-preview'
			//     })
			// }
		},
		back() {
			this.$router.push({
				name: "inventory-upload",
			});
		},
	},
};
</script>
