<template>
  <div>
    <v-row>
      <v-col cols="12">
        <f-card-title>
          {{ $t('labels.currencyPattern') }}
        </f-card-title>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-radio-group v-model="pattern">
          <v-radio label="1.000,00" value="brl" />
          <v-radio label="1,000.00" value="usd" />
        </v-radio-group>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  computed: {
    pattern: {
      get() {
        return this.$store.state.inventory.view.config.currencyPattern;
      },
      set(value) {
        this.$store.commit("inventory/updatePattern", value);
      },
    },
  },
};
</script>
